require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "@/utils/time", "@/components/nav"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("@/utils/time"), require("@/components/nav"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.time, global.nav);
    global.checkTest = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _time, _nav) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  _nav = _interopRequireDefault(_nav);
  var _default = {
    name: "RyPreSaleCheckTestList",
    components: {
      Nav: _nav.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        startTime: '',
        endTime: '',
        number: '',
        list: [],
        isShowModal: false,
        formLabelWidth: '1px',
        pageOptions: {
          pageSizes: [10, 20, 50, 100],
          pageSize: 10,
          total: 0,
          pageNo: 1
        },
        form: {
          probeId: '',
          tagId: '',
          remark: ''
        },
        isConfirmPass: false,
        singleItem: {},
        loading: false,
        fType: '',
        stepNum: 1,
        fileName: '',
        failList: {
          total: 0,
          list: []
        }
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.$localStorage.setItem('uuid', uuid);
      vm.fType = fType;
      vm.name = name;

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        (['yingwo', 'lexing', 'lexing'].indexOf(fType) == -1 || !name) && vm.$router.replace({
          path: "/ryProbe/afterSale/login"
        });
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      vm.pageNo = 1;
      vm.pageSize = 10;
      vm.loadCheckList();
    },
    methods: {
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/ryProbe/afterSale/login"
          });
        }).catch(function () {// on cancel
        });
      },
      search: function search(type) {
        var vm = this;
        var err = '';

        if (vm.number.length < 5 && type != 1) {
          err = '请输入正确的ID';
        }

        if (type == 1 && vm.startTime && vm.endTime) {
          // !(vm.startTime && vm.endTime) && (err = '请选择开始跟结束时间')
          new Date(vm.endTime).getTime() < new Date(vm.startTime).getTime() && (err = '结束时间不能早于开始时间');
        }

        if (err) {
          _toast.default.fail({
            message: err
          });

          return;
        }

        vm.loadCheckList();
      },
      onChangePageNo: function onChangePageNo(pageNo) {
        this.pageOptions.pageNo = pageNo;
        this.loadCheckList();
      },
      onChangePageSize: function onChangePageSize(pageSize) {
        this.pageOptions.pageSize = pageSize;
        this.loadCheckList();
      },
      loadCheckList: function loadCheckList() {
        var vm = this;

        if (['lexing', 'yingwo'].indexOf(vm.fType) == -1) {
          return;
        }

        var params = {
          pageNo: vm.pageOptions.pageNo,
          pageSize: vm.pageOptions.pageSize
        };

        if (vm.fType == 'yingwo') {
          vm.number && (params.probeId = vm.number);
        } else {
          vm.number && (params.uniqueId = vm.number);
        }

        vm.startTime && (params.startAt = new Date(vm.startTime).getTime() / 1000);
        vm.endTime && (params.endAt = new Date(vm.endTime).getTime() / 1000);
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.ADMIN_URL, "admin/iotTool/product/preSales/").concat(vm.fType == 'yingwo' ? 'probe' : 'ryy'),
          params: params,
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.loading = false;
          res.data.list.map(function (item) {
            item.time = (0, _time.getYMD)(item.createAt);
            item.passText = Number(item.passed) ? '通过' : '未通过';
          });
          vm.list = res.data.list;
          vm.pageOptions.total = res.data.total;
          vm.pageOptions.pageNo = vm.pageNo;
        });
      },
      handleClick: function handleClick() {
        this.isShowModal = true;
        this.stepNum = 1;
        this.isConfirmPass = false;
        this.fileName = '';
        this.file = '';
      },
      onCancel: function onCancel() {
        this.editItem = {};
        this.singleItem = {};
        this.singleItem = {};
        this.isShowModal = false;
        this.loading = false;
      },
      onConfirm: function onConfirm() {
        var vm = this;
        vm.loading = true;
        var file = new FormData();
        file.append('file', vm.file);
        vm.$http({
          type: "POST",
          url: "".concat(vm.$config.base.ADMIN_URL, "admin/iotTool/product/preSales/").concat(vm.fType == 'yingwo' ? 'probe' : 'ry', "/check"),
          // url: 'http://127.0.0.1:3011/admin/iotTool/product/preSales/ry/check',
          // except: true,
          data: file
        }).then(function (res) {
          vm.loading = false;
          var code = res.code,
              data = res.data,
              message = res.message;

          if (code != 1) {
            _toast.default.fail({
              message: message
            });

            return;
          }

          vm.failList = data;
          vm.stepNum = 2;
        }).catch(function (err) {
          vm.loading = false;
          console.log(err);
        });
      },
      inputChange: function inputChange(type) {
        var vm = this;

        if (type == 'search') {
          !vm.number && vm.loadCheckList();
          return;
        }
      },
      beforeAvatarUpload: function beforeAvatarUpload(file) {
        var vm = this;
        vm.fileName = file.name;
        vm.isConfirmPass = true;
        vm.file = file;
      }
    }
  };
  _exports.default = _default;
});