var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-prevSale-check-list" },
    [
      _c("Nav"),
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [_vm._v("出厂校验")]),
        _c("div", { staticClass: "header-right" }, [
          _c("span", { staticClass: "btn", on: { click: _vm.switchAccount } }, [
            _vm._v("切换登录")
          ]),
          _c(
            "span",
            { staticClass: "btn add-record", on: { click: _vm.handleClick } },
            [_vm._v("出厂校验")]
          )
        ])
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "filter-box" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-form",
                  { attrs: { "label-width": "90px", center: "" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 10, lg: 10, xl: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "质检时间:" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "startTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "开始时间"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.search(1)
                                        }
                                      },
                                      model: {
                                        value: _vm.startTime,
                                        callback: function($$v) {
                                          _vm.startTime = $$v
                                        },
                                        expression: "startTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "line", attrs: { span: 2 } },
                              [_vm._v("-")]
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "endTime" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "结束时间"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.search(1)
                                        }
                                      },
                                      model: {
                                        value: _vm.endTime,
                                        callback: function($$v) {
                                          _vm.endTime = $$v
                                        },
                                        expression: "endTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "pl-10",
                        attrs: { xs: 24, sm: 12, md: 7, lg: 7, xl: 7 }
                      },
                      [
                        _vm.fType == "yingwo"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "探头ID:", prop: "number" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入探头ID",
                                      maxlength: "8"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.inputChange("search")
                                      }
                                    },
                                    model: {
                                      value: _vm.number,
                                      callback: function($$v) {
                                        _vm.number = $$v
                                      },
                                      expression: "number"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _c(
                                        "span",
                                        { on: { click: _vm.search } },
                                        [_vm._v("搜索")]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.fType == "lexing"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "设备编码:", prop: "number" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入设备编码",
                                      maxlength: "8"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.inputChange("search")
                                      }
                                    },
                                    model: {
                                      value: _vm.number,
                                      callback: function($$v) {
                                        _vm.number = $$v
                                      },
                                      expression: "number"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _c(
                                        "span",
                                        { on: { click: _vm.search } },
                                        [_vm._v("搜索")]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-body" },
          [
            _vm.fType == "yingwo"
              ? _c(
                  "el-table",
                  { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "探头 ID", prop: "probeId" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "质检结果", prop: "passText" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "质检时间", prop: "time" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.fType == "lexing"
              ? _c(
                  "el-table",
                  { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "设备编码", prop: "uniqueId" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "信号强度", prop: "deviceSignal" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作人", prop: "operationUserName" }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "质检时间", prop: "time" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("el-pagination", {
              attrs: {
                "page-no": _vm.pageOptions.pageNo,
                "page-sizes": _vm.pageOptions.pageSizes,
                "page-size": _vm.pageOptions.pageSize,
                center: "",
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.pageOptions.total
              },
              on: {
                "size-change": _vm.onChangePageSize,
                "current-change": _vm.onChangePageNo,
                "update:pageNo": function($event) {
                  return _vm.$set(_vm.pageOptions, "pageNo", $event)
                },
                "update:page-no": function($event) {
                  return _vm.$set(_vm.pageOptions, "pageNo", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "footer" }, [
        _c("img", { attrs: { src: _vm.ossHost + "img/logo.png", alt: "" } })
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.stepNum == 1 ? "上传表格" : "校验结束",
            visible: _vm.isShowModal,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowModal = $event
            },
            close: _vm.onCancel
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stepNum == 1,
                      expression: "stepNum == 1"
                    }
                  ],
                  attrs: { required: "", "label-width": _vm.formLabelWidth }
                },
                [
                  _c("div", [
                    _vm._v("请点击添加excel文件,只支持.xls/.xlsx后缀文件"),
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: _vm.ossHost + "file/1.xlsx"
                        }
                      },
                      [_vm._v("excel模板下载")]
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "probeId" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                accept: ".xls,.xlsx",
                                action: "",
                                "show-file-list": false,
                                "before-upload": _vm.beforeAvatarUpload
                              }
                            },
                            [
                              _vm.fileName
                                ? _c("span", { staticClass: "file-name" }, [
                                    _vm._v(_vm._s(_vm.fileName))
                                  ])
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stepNum == 2,
                      expression: "stepNum == 2"
                    }
                  ],
                  attrs: { required: "", "label-width": _vm.formLabelWidth }
                },
                [
                  _vm.failList.list.length
                    ? _c(
                        "div",
                        [
                          _c("p", [
                            _c("i", { staticClass: "el-icon el-icon-remove" }),
                            _vm._v(
                              "本次校验共" +
                                _vm._s(_vm.failList.total) +
                                "台设备，" +
                                _vm._s(_vm.failList.list.length) +
                                "台未通过质检 "
                            )
                          ]),
                          _vm.fType == "yingwo"
                            ? _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: _vm.failList.list }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "探头 ID", prop: "probeId" }
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "原因", prop: "text" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.fType == "lexing"
                            ? _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: _vm.failList.list }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "探头 ID",
                                      prop: "uniqueId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c("div", [
                        _c("i", { staticClass: "el-icon el-icon-success" }),
                        _c("p", [
                          _vm._v(
                            "本次校验共" +
                              _vm._s(_vm.failList.total) +
                              "台设备，全部通过了出厂质检"
                          )
                        ])
                      ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.onCancel }
                },
                [_vm._v(_vm._s(_vm.stepNum == 1 ? "取 消" : "关 闭"))]
              ),
              _vm.stepNum == 1
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: !_vm.isConfirmPass,
                        loading: _vm.loading
                      },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v("开始校验")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }